import styled  from "styled-components";
import { Image } from "cloudinary-react";

export const Form = styled.form`
`;

export const FieldLayout = styled.div`
  display: flex;
  position: relative;
  background-color: #ffffff;
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 12px;
  margin-right: 12px;
  flex-wrap: wrap;
`;

export const Fieldset = styled.fieldset`
  margin-bottom: 12px;
  margin-right: 12px;
`;

export const Legend = styled.legend`
  font-size: 0.75em;
  background-color: #ffffff;
  color: #979797;
  text-transform: uppercase;
  width: 100%;
  border: 1px solid #ddd;
  border-bottom: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 2px 12px 3px 12px;
`;

export const Label = styled.label`
  display: block;
  font-size: 0.8125em;
  position: absolute;
  top: 3px;
  left: 12px;
  user-select: none;
  transform-origin: top left;
  transition: transform 100ms linear;
  text-transform: uppercase;
  color: #979797;

  input:focus + &,
  select + &,
  input[value]:not([value=""]) + &,
  input[type="date"] ~ &,
  input[type="datetime-local"] ~ & {
    transform: scale(0.75);
  }
`

export const Prefix = styled.div`
  font-size: 0.875em;
  position: absolute;
  left: 12px;
  top: 18px;
  color: #666666;
`;

export const Input = styled.input`
  font-family: inherit;
  font-size: 0.875em;
  outline: none;
  width: 100%;
  flex: 1;
  border: none;
  padding: 18px 12px 6px 12px;
  border-radius: 3px;

  ${Prefix} + & {
    padding-left: 24px;
  }

  &[type="color"] {
    appearance: none;
    height: 48px;
    background-color: transparent;

    &::-webkit-color-swatch, &::-moz-color-swatch {
      border: none;
      border-radius: 3px;
      height: 18px;
      margin-top: 2px;
    }
  }

  ${props => props.$invalid && `
    background-color: #f9dcdb;
  `}

  & + & {
    border-left: 1px solid #ddd;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Errors = styled.div`
  position: absolute;
  right: 0;
  bottom: 100%;
  background-color: #db3a34;
  color: #fff;
  padding: 3px 6px;
  font-size: 0.875em;
  border-radius: 3px;
  opacity: 0;
  transform: translate(-6px, 12px);
  transition: opacity 100ms linear, transform 100ms linear;

  *:focus ~ & {
    opacity: 1;
    transform: translate(-6px, 6px);
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border: 7px solid transparent;
    border-top-color: #db3a34;
    border-bottom: 0;
    transform: translate(-5px, 100%);
  }
`;

export const Select = styled.select`
  font-size: 0.875em;
  border: none;
  background: #fff;
  padding: 18px 12px 6px 12px;
  outline: none;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.05);
  }

  ${props => props.$invalid && `
    background-color: #f9dcdb;
  `}
`;

export const EditorLayout = styled.div`
  flex: 1;
`;

export const EditorStyles = styled.div`
  font-family: sans-serif;

  .Draftail-Editor {
    border: none;
    padding: 24px 12px 6px 12px;
  }

  .Draftail-Toolbar {
    display: flex;
    gap: 6px;
    border: none;
    border-bottom-left-radius: 6px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .Draftail-ToolbarGroup {
    display: flex;
    gap: 6px;

    &:before {
      display: none;
    }

    &:not(:first-child) {
      border: none;
    }
  }

  .Draftail-ToolbarButton {
    color: #333333;
    width: 36px;
    border-radius: 6px;
  }

  .Draftail-ToolbarButton--active {
    border: 1px solid rgba(255,255,255,0.4);
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.25em;

    @media screen and (min-width: 767px) {
      font-size: 1.125em;
      line-height: 1.125em;
    }
  }

  p {
    font-size: 1em;
    line-height: 1.6em;
    padding: 0.5em 0;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 800;
  }

  ol,
  ul {
    font-size: 1em;
    margin-left: 10px;
    padding-left: 10px;

    li {
      line-height: 1.6em;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
`;

export const UploadLayout = styled.div`
  flex: 1;
  position: relative;
  padding: 6px 12px 6px 12px;
  margin-top: 24px;
  background: radial-gradient(#dddddd, #ffffff);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 200px;
`;

export const Dropzone = styled.div`
  position: absolute;
  display: ${props => props.$isDragActive ? 'flex' : 'none'};
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  align-items: center;
  justify-content: center;
  color: rgba(255,255,255,0.80);
  font-weight: bold;
  font-size: 2em;
  pointer-events: none;

  ${props => props.$disabled && "display: none;"}
`;

export const UploadToolbar = styled.div`
  display: flex;
  background: #333333;
  flex-wrap: wrap;
  border: none;
  border-radius: 6px;
  padding: 6px;
  width: 100%;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
`;

export const UploadStatusBar = styled.div`
  display: flex;
  color: #eeeeee;
`;

export const UploadStatus = styled.div`
  font-size: 0.75em;
  font-weight: 700;
  text-transform: uppercase;
`;

export const Preview = styled(Image)`
  margin: auto;
`;

export const InProgress = styled.div`
  position: absolute;
  display: ${props => props.$isUploadActive !== null ? 'flex' : 'none'};
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0,0,0,0.75);
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

export const RadioLayout = styled.div`
  display: flex;
  background-color: #ffffff;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-radius: 3px;
  align-items: center;
  padding: 0 12px;

  &:last-child {
    border-bottom: 1px solid #ddd;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding-bottom: 6px;
  }
`;

export const Radio = styled.input.attrs({
  type: 'radio',
})`
  padding: 0;
  margin: 0;
  height: 18px;
  width: 18px;
  accent-color: #0077cc;
`;

export const RadioLabel = styled.label`
  display: block;
  padding: 3px 6px;
  font-size: 0.875em;
  position: relative;
  white-space: nowrap;
  width: 100%;
  margin-left: 6px;
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  padding: 0;
  margin: 0;
  height: 18px;
  width: 18px;
  margin-left: 12px;
  align-self: center;
  accent-color: #0077cc;
`;

export const CheckboxLabel = styled.label`
  display: block;
  flex: 1;
  padding: 6px;
  font-size: 0.875em;
  position: relative;
  margin-left: 6px;
`;
