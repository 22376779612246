import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router-dom";

export const hotkeys = {
  donations: {
    keys: ["d", 1],
    path: "../donations"
  },
  promoCodes: {
    keys: ["p", 2],
    path: "../promo-codes"
  },
  insights: {
    keys: ["i", 3],
    path: "../insights"
  },
  flashContests: {
    keys: ["f", 4],
    path: "../flash-contests"
  },
  thankYouCampaigns: {
    keys: ["t", 5],
    path: "../thank-you-campaigns",
  },
  site: {
    keys: ["s", 6],
    path: "../site"
  },
};

// A component wrapper is required so we can iterate over the list
export const KeyboardShortcut = ({ keys, path }) => {
  const navigate = useNavigate();
  useHotkeys(keys, () => {
    navigate(path);
  });
  return null;
}

const Hotkeys = () => {
  return Object.values(hotkeys).map(({ keys, path }) => (
    <KeyboardShortcut key={keys} keys={keys} path={path} />
  ));
}

export default Hotkeys;
