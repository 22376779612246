import React from "react";
import { Link } from "react-router-dom";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import Menu from "../../lib/Menu";
import Button from "../design/Button";
import SweepstakesMenu from "../SweepstakesMenu";
import * as UI from "./ui";
import { shortDate } from "../../util/date";

const SweepstakesListItem = ({ sweepstakes }) => {
  const slide = sweepstakes.slides[0];

  return (
    <UI.Card>
      <UI.Status $status={sweepstakes.status}>{sweepstakes.status}</UI.Status>
      <Link to={`sweepstakes/${sweepstakes.id}/`}>
        { slide ? (
          <UI.SweepstakesImage {...slide.image} fetchFormat="auto" width={330} crop="fill" aspectRatio="16:9" />
        ) : (
          <UI.SweepstakesImagePlaceholder />
        )}
      </Link>
      <UI.Details>
        <Link to={`sweepstakes/${sweepstakes.id}/`}>
          <UI.PrizeName>{sweepstakes.prize.name}</UI.PrizeName>
        </Link>
        <UI.ScheduleRange>
          { (sweepstakes.beginsAt && sweepstakes.endsAt) ? (
            `${shortDate(sweepstakes, sweepstakes.beginsAt)} - ${shortDate(sweepstakes, sweepstakes.endsAt)}`
          ) : (
            "No schedule yet"
          )}
        </UI.ScheduleRange>
        <Menu label={<Button size="small" type="submit" icon={faEllipsisV}>Menu</Button>}>
          <SweepstakesMenu sweepstakes={sweepstakes} />
        </Menu>
      </UI.Details>
    </UI.Card>
  );
}

export default SweepstakesListItem;
