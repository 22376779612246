import React from "react";
import { concat, identity } from "ramda";
import { Routes, Route } from "react-router-dom";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'

import * as UI from "./ui";

const TOURS = [
  { id: 551686, path: "promo-codes",         resource: "sweepstakes" },
  { id: 554787, path: "flash-contests",      resource: "sweepstakes" },
  { id: 545827, path: "insights",            resource: "sweepstakes" },
  { id: 564096, path: "thank-you-campaigns", resource: "sweepstakes" },
  { id: 572747, path: "donations",           resource: "sweepstakes" },
  { id: 572461, path: "site",                resource: "sweepstakes" },
  { id: 572472, path: "plugin",              resource: "sweepstakes" },
  { id: 582506, path: "/",                   resource: "nonprofit"   },
];

const RESOURCE_PATHS = {
  sweepstakes: concat("/sweepstakes/:sweepstakesId/"),
  nonprofit: identity,
};

const buildResourcePath = (resource, path) => RESOURCE_PATHS[resource](path);

const TourButton = () => {
  return (
    <Routes>
      {TOURS.map(({ id, path, resource }) => (
        <Route
          key={id}
          path={buildResourcePath(resource, path)}
          element={
            <UI.TourButton intent="tour" size="tiny" icon={faCircleInfo} onClick={() => window.Intercom('startTour', id)}>
              Show Me How
            </UI.TourButton>
          }
        />
      ))}
    </Routes>
  );
}

export default TourButton;
