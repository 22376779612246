import React from "react";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import Button from "../../design/Button";
import ExportButton from "../../ExportButton";
import * as UI from "./ui";
import { isBlank } from "../../../util/presence";
import { formatAsDollars } from "../../../util/money";

const Show = ({ invoice, showCancel, onCancel }) => {
  return (
    <UI.Layout>
      <UI.Header>
        { showCancel && (
          <Button size="small" onClick={onCancel}>❮ Close</Button>
        )}
        <UI.HeaderActions>
          { isBlank(invoice.hostedInvoiceUrl) ? (
            <Button type="submit" size="small" icon={faExternalLinkAlt} disabled>
              View & Pay Invoice
            </Button>
          ) : (
            <Button as="a" href={invoice.hostedInvoiceUrl} target="_blank" rel="noopener noreferrer" type="submit" size="small" icon={faExternalLinkAlt} disabled={isBlank(invoice.hostedInvoiceUrl)}>
              View & Pay Invoice
            </Button>
          )}
        </UI.HeaderActions>
      </UI.Header>

      <UI.Body>
        <UI.Heading>
          <UI.Name>{invoice.number}</UI.Name>
          <UI.HeadingAside>
          <UI.Date>
            Created: {DateTime.fromISO(invoice.createdAt).toLocaleString(DateTime.DATE_FULL)}
          </UI.Date>
          { invoice.sentAt && (
            <UI.Date>
              Sent: {DateTime.fromISO(invoice.sentAt).toLocaleString(DateTime.DATE_FULL)}
            </UI.Date>
          )}
          { invoice.status !== 'paid' && invoice.dueAt && (
            <UI.Date>
              Due: {DateTime.fromISO(invoice.dueAt).toLocaleString(DateTime.DATE_FULL)}
            </UI.Date>
          )}
          { invoice.paidAt && (
            <UI.Date>
              Paid: {DateTime.fromISO(invoice.paidAt).toLocaleString(DateTime.DATE_FULL)}
            </UI.Date>
          )}
          </UI.HeadingAside>
        </UI.Heading>

        <UI.Amount amount={invoice.total} status={invoice.status}>
          {formatAsDollars(invoice.total)}
        </UI.Amount>

        <UI.Field>
          <UI.Label>Sweepstakes</UI.Label>
          <UI.Value>
            <Link to={`../../../sweepstakes/${invoice.sweepstakes.id}`}>
              {invoice.sweepstakes.prize.name}
            </Link>
          </UI.Value>
        </UI.Field>

        <UI.Field>
          <UI.Label>Description</UI.Label>
          <UI.Value style={{ whiteSpace: "pre-wrap" }}>{invoice.description}</UI.Value>
        </UI.Field>

        <UI.ReferenceId>Reference ID: {invoice.id}</UI.ReferenceId>
      </UI.Body>
      <UI.Footer>
        <UI.FooterActions>
          <ExportButton url={invoice.downloadUrl} resource="invoices">
            Manual Donation Entries List
          </ExportButton>
        </UI.FooterActions>
      </UI.Footer>
    </UI.Layout>
  );
}

export default Show;
