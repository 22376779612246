import React from "react";
import { pick } from "ramda";
import { Navigate, Routes, Route } from "react-router-dom";

import useQueryString from "./lib/useQueryString";

import AppShell from "./components/AppShell";
import PublicLayout from "./components/PublicLayout";
import AppLayout from "./components/AppLayout";
import Root from "./components/Root";
import SignIn from "./components/SignIn";
import Dashboard from "./components/Dashboard";
import SweepstakesPages from "./components/SweepstakesPages";
import NonprofitPages from "./components/NonprofitPages";
import ConnectStripe from "./components/ConnectStripe";
import AccountApplicationPage from "./components/AccountApplicationPage";
import GlobalSearchPage from "./components/GlobalSearchPage";
import InvitationPage from "./components/InvitationPage";

const STATE_ALLOW_LIST = ["selectedItemId"];

const parseState = base64State => {
  try {
    const json = atob(base64State);
    const rawState = JSON.parse(json);
    return pick(STATE_ALLOW_LIST, rawState);
  } catch {
    return {};
  }
}

const AppRedirect = () => {
  const { path = "/", state } = useQueryString();
  return <Navigate to={path} state={parseState(state)} replace />
};

const App = () => (
  <AppShell>
    <Routes>
      <Route element={<PublicLayout />}>
        <Route index element={<Root />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/application/*">
          <Route path="*" element={<AccountApplicationPage />} />
        </Route>
        <Route path="/invitation/:invitationToken" element={<InvitationPage />} />
      </Route>
      <Route element={<AppLayout />}>
        <Route path="stripe" element={<ConnectStripe />} />
        <Route path="go" element={<AppRedirect />} />
        <Route path="search" element={<GlobalSearchPage />} />
      </Route>
      <Route path="/:nonprofitId" element={<AppLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="sweepstakes/:sweepstakesId/*">
          <Route path="*" element={<SweepstakesPages />} />
        </Route>
        <Route path="nonprofit/*">
          <Route path="*" element={<NonprofitPages />} />
        </Route>
      </Route>
    </Routes>
  </AppShell>
);

export default App;
