import React from "react";
import { Route, Routes, useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { sweepstakesQuery } from "./gql";
import { REQUIRED_STEPS, OPTIONAL_STEPS } from "./steps";
import OverviewPage from "./OverviewPage";

const Editor = () => {
  const navigate = useNavigate();
  const { sweepstakesId } = useParams();
  const { data, loading, error } = useQuery(sweepstakesQuery, {
    variables: {
      id: sweepstakesId,
    }
  });

  if(loading || error) {
    return null;
  }

  const { sweepstakes } = data;

  return (
    <Routes>
      <Route index element={<OverviewPage sweepstakes={sweepstakes} />} />
      {[...REQUIRED_STEPS, ...OPTIONAL_STEPS].map(step => (
        <Route key={step.id} path={step.id} element={<step.Component {...data} onStepFinish={() => navigate('..')} />} />
      ))}
    </Routes>
  );
}

export default Editor;
