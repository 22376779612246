import React from "react";
import { useQuery } from "@apollo/client";
import { path } from "ramda";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import SetupProgress from "./SetupProgress";
import DurationProgress from "./DurationProgress";
import ThresholdProgress from "./ThresholdProgress";
import Slide from "./Slide";
import StatSummary from "../design/StatSummary";
import Link from "../design/Link";
import { insightsQuery } from "./gql";
import * as UI from "./ui";

const AtAGlance = ({ sweepstakes }) => {
  const { data, loading, error } = useQuery(insightsQuery, {
    variables: { id: sweepstakes.id }
  });

  if(loading || error) return null;

  const insights = path(["sweepstakes", "insights", "all", "data", 0], data);
  const slide = path(["slides", 0], sweepstakes);

  return (
    <UI.Section style={{ gridArea: "at-a-glance" }}>
      <UI.SectionHeading>At a Glance</UI.SectionHeading>
      <UI.Subsection>
        { slide && <Slide image={slide.image} fetchFormat="auto" /> }
        <UI.SubsectionContent>
          { insights && (
            <React.Fragment>
              <StatSummary {...insights} size="large" />
              <Link to="./insights" icon={faArrowRight} size="small">See More Insights</Link>
            </React.Fragment>
          )}
          <DurationProgress sweepstakes={sweepstakes} />
          <ThresholdProgress sweepstakes={sweepstakes} />
          <SetupProgress sweepstakes={sweepstakes} />
        </UI.SubsectionContent>
      </UI.Subsection>
    </UI.Section>
  );
};

export default AtAGlance;
