import React from "react";
import { faPlus, faCheck } from "@fortawesome/free-solid-svg-icons";

import { ordinalize } from "../../util/number";
import Button from "../design/Button";

import { MAX_ADDITIONAL_PRIZES } from "./DrawingTool";
import * as UI from "./ui";

const AdditionalPrizesQueryScreen = ({ sweepstakes, next }) => (
  <React.Fragment>
    <UI.StageContent>
      <UI.StageHeading>You're done unless you have more prizes</UI.StageHeading>
      <UI.InstructionsLayout>
        { sweepstakes.additionalPrizes.length === MAX_ADDITIONAL_PRIZES - 1 ? (
          <p>Do you have a <strong>{ordinalize(sweepstakes.additionalPrizes.length + 2)} prize</strong>? This will be your final prize</p>
        ) : (
          <p>Do you have a <strong>{ordinalize(sweepstakes.additionalPrizes.length + 2)} prize</strong>? You can draw winners for up to {5 - sweepstakes.additionalPrizes.length} more prizes.</p>
        )}
      </UI.InstructionsLayout>
    </UI.StageContent>
    <UI.Controls>
      <Button onClick={() => next({ answer: true })} icon={faPlus}>Add Another Prize</Button>
      <Button type="submit" onClick={() => next({ answer: false })} icon={faCheck}>No, I'm done</Button>
    </UI.Controls>
  </React.Fragment>
);

export default AdditionalPrizesQueryScreen;
