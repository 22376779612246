import React from "react";

import * as UI from "./ui";

const Slide = ({ image, width = 348 }) => (
  <UI.Slide
    {...image}
    width={width}
    crop="fill"
    aspectRatio="16:9"
    fetchFormat="auto"
    style={{ gridArea: "slide" }}
  />
);

export default Slide;
