import React, { useMemo, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import PluginConfigurationForm from "./PluginConfigurationForm";
import PluginCode from "./PluginCode";

const urlEncodeHex = hex => hex.replace("#", "%23");
const buildPluginOptions = ({ displayMode, primaryColor }) => [
  `displayMode=${displayMode}`,
  `textColor=${displayMode === "light" ? "%23000" : "%23fff"}`,
  `primaryColor=${urlEncodeHex(primaryColor)}`,
].join("&");

const Guide = ({ sweepstakes }) => {
  const nonprofitColor = sweepstakes.nonprofit.profile.color || "#00c19d";
  const [configuration, setConfiguration] = useState({
    displayMode: 'light',
    primaryColor: nonprofitColor,
    facebook: true,
    googleAnalytics: true,
    googleAds: false,
    googleAdsCampaign: "",
  });
  const navigate = useNavigate();
  const pluginUrl = useMemo(
    () => `${process.env.REACT_APP_PLUGIN_URL}/${sweepstakes.id}?${buildPluginOptions(configuration)}`,
    [sweepstakes.id, configuration],
  );

  return (
    <Routes>
      <Route
        index
        element={
          <PluginConfigurationForm
            nonprofitColor={nonprofitColor}
            configuration={configuration}
            setConfiguration={setConfiguration}
            pluginUrl={pluginUrl}
            onComplete={() => { navigate("code"); }}
          />
        }
      />
      <Route
        path="code"
        element={<PluginCode configuration={configuration} pluginUrl={pluginUrl} back={() => { navigate(".."); }} />}
      />
    </Routes>
  );
};

export default Guide;
