import { gql } from "@apollo/client";

export const createSweepstakesMutation = gql`
  mutation CreateSweepstakes($nonprofitId: NonprofitSlug!, $attributes: NewSweepstakesInput!) {
    createSweepstakes(nonprofitId: $nonprofitId, attributes: $attributes) {
      sweepstakes {
        id
        approved
        beginsAt
        endsAt

        schedule {
          id
          drawn
        }

        prize {
          id
          name

          win {
            id
            confirmed
          }
        }

        slides {
          id
          image {
            publicId
            cloudName
            version
          }
        }
      }
      error {
        code
        message
      }
    }
  }
`;
